// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-components-jsx": () => import("./../../../src/pages/components.jsx" /* webpackChunkName: "component---src-pages-components-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-release-archive-jsx": () => import("./../../../src/templates/release-archive.jsx" /* webpackChunkName: "component---src-templates-release-archive-jsx" */),
  "component---src-templates-release-jsx": () => import("./../../../src/templates/release.jsx" /* webpackChunkName: "component---src-templates-release-jsx" */),
  "component---src-templates-resources-jsx": () => import("./../../../src/templates/resources.jsx" /* webpackChunkName: "component---src-templates-resources-jsx" */),
  "component---src-templates-tip-jsx": () => import("./../../../src/templates/tip.jsx" /* webpackChunkName: "component---src-templates-tip-jsx" */),
  "component---src-templates-video-archive-jsx": () => import("./../../../src/templates/video-archive.jsx" /* webpackChunkName: "component---src-templates-video-archive-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */)
}

